<template>
  <div class="error">
    <NuxtLayout>
      <BasicContent :content="content">
        <template #button-group>
          <BaseLink
            type="button"
            class="button button--semi-transparent"
            url="/"
          >
            {{ $t("general.homepage") }}
          </BaseLink>
          <BaseLink
            type="button"
            class="button button--semi-transparent"
            :url="{ name: 'contact' }"
          >
            {{ $t("general.contact") }}
          </Baselink>
        </template>
      </BasicContent>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import { useError, useRuntimeConfig } from 'nuxt/app';
import { onBeforeMount, onMounted } from 'vue';
import { useI18n } from '#i18n';
import BasicContent from '@/components/layout/BasicContent.vue';
import { getApi } from '@/stores/configuration';
import { useNavigationStore } from '@/stores/navigation';
import * as Sentry from '@sentry/vue';

const i18n = useI18n();
const error = useError();

Sentry.captureException(error.value);

const content = {
  title:
    error.value?.statusCode === 404
      ? i18n.t('general.error.page_not_found_title')
      : i18n.t('general.error.error_occurred_title'),
  subtitle:
    error.value?.statusCode === 404
      ? i18n.t('general.error.page_not_found_subtitle')
      : i18n.t('general.error.error_occurred_subtitle'),
  content: i18n.t('general.error.text'),
};

onBeforeMount(async () => {
  // Nuxt error page does not execute server side code in static site generator
  const config = useRuntimeConfig();
  const api = getApi(config.public.apiUrl as string);
  const navigationStore = useNavigationStore();
  await navigationStore.fetchNavigation(
    api,
    i18n,
    config.public.theme as string,
  );
});

onMounted(() => {
  // @workaround
  // Because of a bug, the links on the error page only change the address bar
  // and not the content of the page itself. This code removes all event listeners
  // so the links function as normal html links and do not use the Nuxt router.
  const links = document.querySelectorAll('a');
  links.forEach((link) => {
    // Clone the link element to remove all existing event listeners
    const newLink = link.cloneNode(true);
    link.parentNode?.replaceChild(newLink, link);
  });
});
</script>
