import { NavigationApi, Configuration } from '@/api';

export function getConfiguration() {
  return new Configuration({});
}

export function getApi(apiUrl = '') {
  const configuration = getConfiguration();
  return new NavigationApi(configuration, apiUrl);
}
