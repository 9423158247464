import { gsap } from 'gsap';

export default function useGsapDisabler() {
  const isCypress = typeof Cypress !== 'undefined' && Cypress.env('ci');
  const forceDisable = false; // Set to true to disable animations.

  const disableGsapAnimations = (): void => {
    if (!isCypress && !forceDisable) {
      return;
    }

    // Get all configured tweens and execute them.
    gsap.getTweensOf('*').forEach((tween) => {
      tween.play();
    });
  };

  return {
    disableGsapAnimations,
  };
}
