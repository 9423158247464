import { defineNuxtRouteMiddleware, navigateTo } from 'nuxt/app';
import MIDDLE_EAST_COUNTRIES from '@/constants/countries';
import { EUROPE, MIDDLE_EAST } from '@/constants/Themes';
import { MIDDLE_EAST_LOCALES } from '@/constants/locales';

export default defineNuxtRouteMiddleware((to) => {
  const theme = process.env.THEME || EUROPE;
  if (theme !== MIDDLE_EAST) {
    return undefined;
  }
  const path = to.path.split('/');
  if (MIDDLE_EAST_LOCALES.includes(path[1])) {
    if (MIDDLE_EAST_COUNTRIES.includes(path[2])) {
      return undefined;
    }
    path.splice(2, 0, 'sa');
    return navigateTo(path.join('/'));
  }
  return undefined;
});
