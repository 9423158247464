import { EUROPE, MIDDLE_EAST } from '@/constants/Themes';

export type Locale = string;
type LocaleArray = Locale[];
type LocaleRecord = Record<string, LocaleArray>;

export const EN = 'en';
export const AR = 'ar';
export const NL = 'nl';
export const ES = 'es';
export const IT = 'it';

export const MIDDLE_EAST_LOCALES = [EN, AR];

export const LOCALES: LocaleRecord = {
  [MIDDLE_EAST]: [EN, AR],
  [EUROPE]: [NL, ES, IT],
};
