import { type ProductCategory } from '@/types/Product';
import { NL, IT } from '@/constants/locales';

export const BREAKFAST_MEALS = 'breakfast';
export const FAMILY_MOMENTS = 'family';
export const SOCIAL_MOMENTS = 'social';
export const SPECIAL_MEALS = 'special';

const categoriesMiddleEast: ProductCategory[] = [
  {
    slug: FAMILY_MOMENTS,
    image: '/products/middle-east/bag_original_fries.webp',
    secondImage: '/products/product_family_moments.webp',
  },
  {
    slug: SPECIAL_MEALS,
    image: '/products/middle-east/bag_twister_fries.webp',
    secondImage: '/products/product_special_moments.webp',
  },
  {
    slug: SOCIAL_MOMENTS,
    image: '/products/middle-east/bag_potato_dippers.webp',
    secondImage: '/products/product_social_moments.webp',
  },
  {
    slug: BREAKFAST_MEALS,
    image: '/products/middle-east/bag_hash_browns.webp',
    secondImage: '/products/product_breakfast_meals.webp',
  },
];

export const FRIES_SEASONED = 'tasty_shapes_seasoned';
export const FRIES_NATURAL = 'tasty_shapes_natural';
export const HOMESTYLE = 'homestyle';
export const SPECIALITIES = 'specialities';

const categoriesEurope: ProductCategory[] = [
  {
    slug: FRIES_SEASONED,
    image: '/products/category-tasty-shapes-seasoned.webp',
    locales: [NL, IT],
  },
  {
    slug: FRIES_NATURAL,
    image: '/products/category-tasty-shapes-natural.webp',
    locales: [NL, IT],
  },
  {
    slug: HOMESTYLE,
    image: '/products/category-homestyle.webp',
    locales: [NL, IT],
  },
  {
    slug: SPECIALITIES,
    image: '/products/category-specialities.webp',
    locales: [NL, IT],
  },
];

const productCategories: { [key: string]: ProductCategory[] } = {
  europe: categoriesEurope,
  'middle-east': categoriesMiddleEast,
};

export default productCategories;
