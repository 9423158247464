import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { defineNuxtPlugin } from 'nuxt/app';

export default defineNuxtPlugin(() => {
  gsap.registerPlugin(ScrollTrigger);
  if (typeof Cypress !== 'undefined' && Cypress.env('ci')) {
    gsap.globalTimeline.timeScale(1000);
  }
});
