import { useWindowSize, useScreenOrientation } from '@vueuse/core';
import { computed } from 'vue';
import {
  TABLET, DESKTOP, MOBILE_MENU_BREAKPOINT, PRODUCT_BREAKPOINT,
} from '@/constants/breakpoints';

export default function useUIHelper() {
  const { width } = useWindowSize();
  const { orientation } = useScreenOrientation();

  const isMobileMenuBreakpoint = computed((): boolean => width.value < MOBILE_MENU_BREAKPOINT);
  const isMobileDevice = computed((): boolean => width.value < TABLET || (width.value < TABLET && orientation.value === 'portrait-primary'));
  const isTabletDevice = computed((): boolean => width.value >= TABLET && width.value < DESKTOP);
  const isDesktopDevice = computed((): boolean => width.value >= DESKTOP);
  const isProductDetailMobile = computed((): boolean => width.value < PRODUCT_BREAKPOINT);

  return {
    isMobileMenuBreakpoint,
    isMobileDevice,
    isTabletDevice,
    isDesktopDevice,
    isProductDetailMobile,
  };
}
