<template>
  <div
    class="background"
    :class="{
      'background--include-header': includeHeader,
      'background--clouds': category === SOCIAL_MOMENTS,
      'background--disabled': disableConcrete,
      'background--europe': currentTheme === EUROPE,
    }"
  >
    <div
      v-if="(category !== '' || showWaves) && currentTheme === MIDDLE_EAST"
      class="background-patterns"
    >
      <WaveGroup
        v-if="category === SPECIAL_MEALS || showWaves"
        class="background-patterns__wave-group"
        :class="{
          'background-patterns__wave-group--horizontal': horizontalWaves,
        }"
      />
      <div v-if="category === SPECIAL_MEALS">
        <PatternStar
          v-for="index in 6"
          :key="index"
          :class="`background-patterns__star background-patterns__star--${index}`"
        />
      </div>

      <PatternHearts
        v-if="category === FAMILY_MOMENTS || showHearts"
        class="background-patterns__hearts"
      />

      <PatternSun
        v-if="category === BREAKFAST_MEALS"
        class="background-patterns__sun"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRuntimeConfig } from 'nuxt/app';
import {
  BREAKFAST_MEALS, FAMILY_MOMENTS, SOCIAL_MOMENTS, SPECIAL_MEALS,
} from '@/constants/ProductCategories';
import { EUROPE, MIDDLE_EAST } from '@/constants/Themes';
import PatternHearts from '@/assets/patterns/hearts.svg?component';
import PatternStar from '@/assets/patterns/star.svg?component';
import PatternSun from '@/assets/patterns/sun.svg?component';
import WaveGroup from '@/assets/patterns/wave-group.svg?component';

defineProps({
  includeHeader: {
    type: Boolean,
    default: false,
  },
  disableConcrete: {
    type: Boolean,
    default: false,
  },
  category: {
    type: String,
    required: false,
    default: '',
  },
  showWaves: {
    type: Boolean,
    default: false,
  },
  horizontalWaves: {
    type: Boolean,
    default: false,
  },
  showHearts: {
    type: Boolean,
    default: false,
  },
});

const config = useRuntimeConfig();
const currentTheme = config.public.theme;
</script>

<style scoped lang="scss">
.background {
  position: absolute;
  inset: 0;
  background-image: url('/backgrounds/base-blue.webp');
  background-size: cover;
  background-attachment: fixed;
  z-index: map-get($z-index, 'background');

  &--europe {
    // Background img is necessary for clip-path
    background-image: url('/backgrounds/dark-grey.svg');
  }

  &--disabled {
    background-image: none;
  }

  &--include-header {
    transform: scale(1.3);
  }

  &--clouds::after {
    display: block;
    content: '';
    position: absolute;
    inset: 0;
    background-image: url('/patterns/clouds.webp');
    background-size: cover;
    background-position: center;
    opacity: $background-patterns-opacity;
  }
}

.background-patterns {
  &__wave-group,
  &__hearts,
  &__sun {
    opacity: $background-patterns-opacity;
  }

  &__wave-group {
    transform: scale(1.5);
    animation: fade-in-half 4s ease-in;

    &--horizontal {
      transform: scale(1.5) rotate(40deg);

      :deep(.wave--top) {
        display: none;
      }
    }
  }

  &__star {
    position: absolute;

    &--1 {
      inset-block-start: random-percentage(5, 33);
      inset-inline-start: random-percentage(0, 40);
    }

    &--2 {
      inset-block-start: random-percentage(5, 33);
      inset-inline-end: random-percentage(0, 40);
    }

    &--3 {
      inset-block-start: random-percentage(33, 66);
      inset-inline-start: random-percentage(0, 40);
    }

    &--4 {
      inset-block-start: random-percentage(33, 66);
      inset-inline-end: random-percentage(0, 40);
    }

    &--5 {
      inset-block-start: random-percentage(66, 100);
      inset-inline-start: random-percentage(0, 40);
    }

    &--6 {
      inset-block-start: random-percentage(66, 100);
      inset-inline-end: random-percentage(0, 40);
    }
  }
}
</style>
