const MIDDLE_EAST_COUNTRIES = [
  'sa', // Saudi Arabia
  'ae', // Arab Emirates
  'kw', // Kuwait
  'jo', // Jordan
  'om', // Oman
  'bh', // Bahrain
  'iq', // Iraq
  // 'qa', // Qatar
];

export default MIDDLE_EAST_COUNTRIES;
