import { defineStore } from 'pinia';
import { ref } from 'vue';
import { type ProductVariant } from '@/types/Campaign';

const useCampaignStore = defineStore('campaign', () => {
  const productVariant = ref<ProductVariant>('seasoned');

  const toggleProductVariant = (): void => {
    productVariant.value = productVariant.value === 'natural' ? 'seasoned' : 'natural';
  };

  const getProductVariantLabel = () : string => (productVariant.value === 'natural' ? 'Natural' : 'Seasoned');

  return {
    productVariant,
    toggleProductVariant,
    getProductVariantLabel,
  };
});

export default useCampaignStore;
