/* eslint-disable */
import { MIDDLE_EAST } from '@/constants/Themes';
import { defineStore } from 'pinia';
import * as Sentry from "@sentry/vue";

export const useNavigationStore = defineStore('navigation', {
  state: () => ({
    menus: {},
  }),
  getters: {
    main(state) {
      return (locale) => state.menus[locale]?.main?.children;
    },
    footer(state) {
      return (locale) =>
        Object.fromEntries(
          Object.entries(state.menus[locale] ?? {}).filter(([key]) =>
            key.includes('footer')
          )
        );
    },
  },
  actions: {
    async fetchNavigation(api: any, i18n: any, currentTheme: string) {
      try {
        for (const locale of i18n.locales.value) {
          const { data } = await api.apiWebspaceLocaleNavigationGet({
            webspace:
              currentTheme === MIDDLE_EAST ? 'retail-arabic' : 'retail-europe',
            locale: locale.code,
          });

          this.setNavigation({ data, locale: locale.code });
        }
      } catch (e) {
        Sentry.captureException(e);
      }
    },
    setNavigation({ data, locale }) {
      this.menus[locale] = data;
      this.menus = { ...this.menus };
    },
  },
});
