<template>
  <CursorFollower />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import useGsapDisabler from '@/composables/useGsapDisabler';
import CursorFollower from '@/components/CursorFollower.vue';

const { disableGsapAnimations } = useGsapDisabler();

onMounted(() => {
  disableGsapAnimations();
});
</script>
