import { useRuntimeConfig } from 'nuxt/app';
import { getApi } from '@/stores/configuration';
import { useNavigationStore } from '@/stores/navigation';

export default defineNuxtPlugin(async (nuxtApp) => {
  const navigationStore = useNavigationStore();
  if (process.server) {
    const config = useRuntimeConfig();
    const i18n = nuxtApp.$i18n;
    const api = getApi(config.public.apiUrl);
    await navigationStore.fetchNavigation(api, i18n, config.public.theme);
  }

  nuxtApp.provide('navigationStore', navigationStore);
});
