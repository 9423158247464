import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import { createVuetify, type ThemeDefinition } from 'vuetify';
import {
  nl, es, en, ar,
} from 'vuetify/locale';
import { MIDDLE_EAST } from '@/constants/Themes';

const customDarkTheme: ThemeDefinition = {
  colors: {
    error: '#E35205', // secondary 5
  },
};

export default defineNuxtPlugin((app: any) => {
  const config = useRuntimeConfig();
  const { theme } = config.public;

  const vuetify = createVuetify({
    theme: {
      defaultTheme: 'dark',
      themes: {
        dark: customDarkTheme,
      },
    },
    ssr: true,
    defaults: {
      global: {
        variant: 'outlined',
        rounded: 'xl',
      },
      VList: {
        variant: 'default',
        bgColor: theme === MIDDLE_EAST ? '#005c97' : 'default',
      },
      VListItem: {
        rounded: '0',
      },
      VFileInput: {
        prependIcon: '',
        prependInnerIcon: 'mdi-camera',
        showSize: true,
      },
    },
    locale: {
      messages: {
        nl, es, en, ar,
      },
    },
  });
  app.vueApp.use(vuetify);
});
