<!-- This component can show internal, external or no link -->
<template>
  <span v-if="!url">
    <slot />
  </span>
  <a v-else-if="isExternal" :href="url as string" target="_blank" rel="noopener noreferrer">
    <slot />
  </a>
  <NuxtLink v-else-if="internalUrl" :to="localePath(internalUrl)" no-rel target="_self">
    <slot />
  </NuxtLink>
</template>

<script setup lang="ts">
import { computed, type PropType } from 'vue';
import { useRoute } from 'nuxt/app';
import { useLocalePath } from '#i18n';

const localePath = useLocalePath();
const requestUrl = useRequestURL();

const props = defineProps({
  url: {
    type: [String, Object] as PropType<string | object>,
    required: false,
    default: '',
  },
});

const route = useRoute();

const isUrlString = computed((): boolean => typeof props.url === 'string');

const isUrlContainingCurrentHost = computed(
  (): boolean => isUrlString.value && props.url.includes(requestUrl.origin),
);

const isExternal = computed(
  (): boolean => isUrlString.value && props.url.startsWith('http') && !isUrlContainingCurrentHost.value,
);

const internalUrl = computed((): string | object => {
  // Route is given as object
  if (!isUrlString.value) {
    return props.url;
  }

  const url = props.url as string;
  if (url.startsWith('#')) {
    return url;
  }
  if (isUrlContainingCurrentHost.value) {
    return new URL(url, requestUrl.origin).pathname;
  }
  if (route.params.country && !url.startsWith(`/${route.params.country}`)) {
    // Prefix path with country for Middle East
    return `/${route.params.country}${url}`;
  }
  return url;
});
</script>
