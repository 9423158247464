<template>
  <!-- Add a wrapper to prevent unmounting bug when switching layout -->
  <div class="cursor__wrapper">
    <div ref="cursor" class="cursor" :class="[{ 'cursor--hover': hover }, `cursor--${type}`]">
      <CampaignCTA
        v-if="type === 'campaign'"
        icon-url="/icons/curly-fry.svg"
        :text="
          campaignStore.productVariant === 'natural'
            ? $t('campaign.add-seasoning')
            : $t('campaign.remove-seasoning')
        "
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'nuxt/app';
import {
  ref, onMounted, nextTick, watch, onBeforeUnmount,
} from 'vue';
import { useMouse } from '@vueuse/core';
import { gsap } from 'gsap';
import useUIHelper from '@/composables/useUIHelper';
import CampaignCTA from '@/components/campaign/CampaignCTA.vue';
import useCampaignStore from '@/stores/campaign';

const route = useRoute();
const campaignStore = useCampaignStore();
const { isDesktopDevice } = useUIHelper();

const type = ref('');
const hover = ref(false);
const cursor = ref(null as Element | null);

const { x: mouseX, y: mouseY } = useMouse({ type: 'client', touch: false });
const { sourceType } = useMouse();

const position = { x: mouseX, y: mouseY };
const velocity = { x: 0, y: 0 };

let xSetter: Function | null = null;
let ySetter: Function | null = null;

const cursorHover = (dataCursorValue: string) => {
  type.value = dataCursorValue;
  hover.value = true;
};

const disableCursorHover = () => {
  type.value = '';
  hover.value = false;
};

const onMouseMove = (e: MouseEvent): void => {
  const cursorSize = cursor.value?.clientWidth;
  const x = e.clientX - cursorSize! / 2;
  const y = e.clientY - cursorSize! / 2;

  gsap.to(position, {
    x,
    y,
    duration: 1.25,
    ease: 'Expo.easeOut',
    onUpdate: () => {
      velocity.x = x - position.x;
      velocity.y = y - position.y;
    },
  });
};

const loop = () => {
  xSetter(position.x);
  ySetter(position.y);
};

const switchProductVariant = () => {
  if (sourceType.value === 'mouse' && isDesktopDevice.value) {
    campaignStore.toggleProductVariant();
  }
};

// Add event listeners to all elements which should show hover
const initHoverElements = () => {
  const dataCursorElements = document.querySelectorAll('[data-cursor]');

  [...dataCursorElements].forEach((elem) => {
    const dataCursorValue = elem.getAttribute('data-cursor') as string;
    elem.addEventListener('mouseenter', () => cursorHover(dataCursorValue), false);
    elem.addEventListener('mouseleave', disableCursorHover, false);
    if (dataCursorValue === 'campaign') {
      elem.addEventListener('click', switchProductVariant);
      elem.style.cursor = 'pointer';
    }
  });

  if (dataCursorElements.length > 0) {
    window.addEventListener('mousemove', onMouseMove);
    gsap.ticker.add(loop);
  }
};

// Remove event listeners to all hover elements
const uninitHoverElements = () => {
  gsap.ticker.remove(loop);
  window.removeEventListener('mousemove', onMouseMove);

  [...document.querySelectorAll('[data-cursor]')].forEach((elem) => {
    elem.removeEventListener('mouseenter', () => cursorHover, false);
    elem.removeEventListener('mouseleave', disableCursorHover, false);
    if (elem.getAttribute('data-cursor') === 'campaign') {
      elem.removeEventListener('click', switchProductVariant);
    }
  });
};

onMounted(() => {
  xSetter = gsap.quickSetter(cursor.value, 'x', 'px');
  ySetter = gsap.quickSetter(cursor.value, 'y', 'px');

  nextTick(() => {
    initHoverElements();
  });
});

onBeforeUnmount(() => {
  uninitHoverElements();
});

watch(
  () => [route.fullPath],
  () => {
    disableCursorHover();
    uninitHoverElements();

    nextTick(() => {
      // Without the timeout the function still gets called too early sometimes
      setTimeout(initHoverElements, 500);
    });
  },
);
</script>

<style lang="scss" scoped>
$transition-duration: 0.16s;

.cursor {
  pointer-events: none;
  position: fixed;
  inset-inline-start: 0;
  inset-block-start: 0;
  opacity: 0;
  transform-origin: 50% 50%;
  transition: opacity $transition-duration linear,
    inline-size $transition-duration linear,
    block-size $transition-duration linear,
    background-color $transition-duration linear,
    box-shadow $transition-duration linear;
  will-change: opacity, inline-size, block-size, transform, background-color, box-shadow;

  @include respond-max($desktop) {
    display: none;
  }

  // Never show the cursor on touch devices
  @media (hover: none) {
    display: none;
  }

  &--lighting {
    z-index: map-get($z-index, 'below');
    inline-size: 30px;
    block-size: 30px;
    background-color: $white;
    border-radius: 50%;

    &.cursor--hover {
      opacity: 0.05;
      inline-size: 500px;
      block-size: 500px;
      box-shadow: 0 0 250px 250px rgba($white, 1);
    }
  }

  &--campaign {
    z-index: map-get($z-index, 'campaign-cta');
    border-radius: 50%;

    &.cursor--hover {
      opacity: 1;
    }
  }

  &--lighting-campaign {
    z-index: map-get($z-index, 'lighting-campaign');
    inline-size: 30px;
    block-size: 30px;
    background-color: $campaign-slogan;
    border-radius: 50%;
    mix-blend-mode: screen;

    &.cursor--hover {
      opacity: 0.4;
      inline-size: 300px;
      block-size: 300px;
      box-shadow: 0 0 150px 150px rgba($campaign-slogan, 1);
    }
  }
}
</style>
