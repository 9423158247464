import revive_payload_client_UTN6mGOzci from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.58.9_axios@1.6.5_encodi_lbarm63pfshuo5zg26ehx7svka/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_kOdhBJ6h92 from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.58.9_axios@1.6.5_encodi_lbarm63pfshuo5zg26ehx7svka/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_uvyAT4V2T2 from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.58.9_axios@1.6.5_encodi_lbarm63pfshuo5zg26ehx7svka/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_hhGBpGAJpS from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.58.9_axios@1.6.5_encodi_lbarm63pfshuo5zg26ehx7svka/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_iyOgAdNnBd from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.58.9_axios@1.6.5_encodi_lbarm63pfshuo5zg26ehx7svka/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_GHA6JKhzO4 from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.14.3_typescript@5.4.5_vue@3.4.23_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_DQ6dQc2AER from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.58.9_axios@1.6.5_encodi_lbarm63pfshuo5zg26ehx7svka/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_FlgTwJkiBQ from "/app/node_modules/.pnpm/vue-recaptcha@3.0.0-alpha.6_rollup@4.14.3_vue@3.4.23_typescript@5.4.5_/node_modules/vue-recaptcha/dist/nuxt-plugin.mjs";
import plugin_bjcpX0Mttu from "/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocs_ihctzennfj53jtgmdpzd427dzu/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import i18n_Bbx9aHxYGQ from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.2.0_rollup@4.14.3_vue@3.4.23_typescript@5.4.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_qqjnA7okJj from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.58.9_axios@1.6.5_encodi_lbarm63pfshuo5zg26ehx7svka/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import gsap_FnwzRby0Oe from "/app/src/plugins/gsap.ts";
import emitter_5zAQVt1oGs from "/app/src/plugins/emitter.ts";
import init_navigation_Ny4GeHzG54 from "/app/src/plugins/init-navigation.ts";
import lottie_jvNkrOddXP from "/app/src/plugins/lottie.ts";
import sentry_h0fMjCG9AB from "/app/src/plugins/sentry.ts";
import social_share_dmDUvjWlhi from "/app/src/plugins/social-share.ts";
import vee_validate_fgGHHmjH3T from "/app/src/plugins/vee-validate.ts";
import vuetify_F4zXYcNIwC from "/app/src/plugins/vuetify.ts";
export default [
  revive_payload_client_UTN6mGOzci,
  unhead_kOdhBJ6h92,
  router_uvyAT4V2T2,
  payload_client_hhGBpGAJpS,
  check_outdated_build_client_iyOgAdNnBd,
  plugin_vue3_GHA6JKhzO4,
  components_plugin_KR1HBZs4kY,
  prefetch_client_DQ6dQc2AER,
  nuxt_plugin_FlgTwJkiBQ,
  plugin_bjcpX0Mttu,
  i18n_Bbx9aHxYGQ,
  chunk_reload_client_qqjnA7okJj,
  gsap_FnwzRby0Oe,
  emitter_5zAQVt1oGs,
  init_navigation_Ny4GeHzG54,
  lottie_jvNkrOddXP,
  sentry_h0fMjCG9AB,
  social_share_dmDUvjWlhi,
  vee_validate_fgGHHmjH3T,
  vuetify_F4zXYcNIwC
]