<template>
  <!-- ClientOnly prevents Hydration mismatches which results in sizing issues on mobile -->
  <ClientOnly>
    <div
      class="campaign-cta"
      :class="{ 'campaign-cta--hover': hover }"
      :style="`width: ${radius}px; height: ${radius}px`"
    >
      <svg class="campaign-cta__circle-svg" :viewBox="`0 0 ${radius} ${radius}`">
        <defs>
          <pattern
            id="imagePattern"
            x="0"
            y="0"
            patternUnits="userSpaceOnUse"
            :width="radius"
            :height="radius"
          >
            <image
              class="campaign-cta__image"
              :href="iconUrl"
              :x="radius / 6"
              :y="radius / 6"
              :width="radius / 1.5"
              :height="radius / 1.5"
            />
          </pattern>
        </defs>
        <path
          id="textcircle"
          class="campaign-cta__circle-path"
          :d="circlePath"
          fill="url(#imagePattern)"
        />
        <g class="campaign-cta__text-wrapper">
          <g v-for="index in textAmount" :key="`textGroup-${index}`">
            <text :dy="-textOffset" :word-spacing="spacing">
              <textPath
                xlink:href="#textcircle"
                class="campaign-cta__text-path"
                :startOffset="`${(index - 1) * (100 / textAmount)}%`"
                :textLength="`${300 / textAmount}%`"
              >
                <tspan class="campaign-cta__bulletpoint">&#8226;</tspan>
                <tspan class="campaign-cta__text" :dx="spacing">
                  {{ text }}
                </tspan>
              </textPath>
            </text>
          </g>
        </g>
      </svg>
    </div>
  </ClientOnly>
</template>

<script setup lang="ts">
import { computed, type PropType } from 'vue';

const props = defineProps({
  hover: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  iconUrl: {
    type: String as PropType<string>,
    required: true,
  },
  radius: {
    type: Number as PropType<number>,
    default: 92,
  },
  text: {
    type: String as PropType<string>,
    required: true,
  },
  spacing: {
    type: Number as PropType<number>,
    default: 5,
  },
  textAmount: {
    type: Number as PropType<number>,
    default: 3,
  },
  textOffset: {
    type: Number as PropType<number>,
    default: 20,
  },
});

const circlePath = computed(() => {
  const { radius } = props;
  return `M${radius / 2}, ${radius}
            C${radius / 4.46}, ${radius}, 0, ${radius / 1.29}, 0, ${radius / 2}
            S${radius / 4.46}, 0, ${radius / 2}, 0
            s${radius / 2}, ${radius / 4.46}, ${radius / 2}, ${radius / 2}
            S${radius / 1.29}, ${radius}, ${radius / 2}, ${radius}z`;
});
</script>

<style scoped lang="scss">
.campaign-cta {
  $self: &;

  background-color: rgba($black, 0.1);
  border-radius: 50%;
  filter: drop-shadow(0 1px 1px rgba($black, 0.7));
  transition: background-color 0.3s ease;

  &--hover {
    &:hover {
      background-color: rgba($campaign-color-dark, 0.75);

      #{$self} {
        &__image {
          transform-origin: center;
          animation: rotate-svg 0.75s;
        }

        @keyframes rotate-svg {
          to {
            transform: rotate(1turn);
          }
        }
      }
    }
  }

  &__circle-svg {
    overflow: visible;
  }

  &__circle-path {
    stroke-width: 2px;
    stroke: $campaign-color-light;
  }

  &__text-wrapper {
    font-size: to-rem(12);
    fill: $campaign-color-light;
    transform-origin: center;
    animation-name: rotate-circle;
    animation-duration: 10s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  @keyframes rotate-circle {
    to {
      transform: rotate(1turn);
    }
  }

  &__text-path {
    text-transform: uppercase;
    font-family: $campaign-secondary-font;
    font-weight: 700;
    text-shadow: 0 1px 1px rgba($black, 0.5);
  }

  &__bulletpoint {
    fill: $which-haze;
  }
}
</style>
